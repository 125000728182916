import { IMAGES_PREFIX } from "~config/config-global"

const orderPath = "/order/"
const artistsPath = "/artists/"
const defaultArtistImage = IMAGES_PREFIX + '/assets/images/orders/Pick-your-artist-1.png'
const defaultArtistImage1 = IMAGES_PREFIX + '/assets/images/mud-eater-square.png'
const stepperIconActive = IMAGES_PREFIX + '/assets/icons/home/stepper-icon-incomplete.png'
const stepperIcon = IMAGES_PREFIX + '/assets/icons/home/stepper-icon.svg'
const dateImage = IMAGES_PREFIX + '/assets/icons/grey-calender.png'
const activeDateImage = IMAGES_PREFIX + '/assets/pink-calender-1.png'
const orderCompleteImg = IMAGES_PREFIX + '/assets/images/ordercomplete/ordercomplete.png'
const meterImg = IMAGES_PREFIX + '/assets/images/ordercomplete/meter.png'
const orderOvelayImg = IMAGES_PREFIX + '/assets/images/orders/overlay-m.png'
const occasionPath = '/occasions/'
const orderCompPath = '/ordercomplete/'
const songPath = '/song/'
const artistPath = '/artist/'
const downloadPath = IMAGES_PREFIX + '/assets/images/ordercomplete/download.png'
const justHearPath = IMAGES_PREFIX + '/assets/images/just-hear.png'
const shareSongPath = IMAGES_PREFIX + '/assets/images/ordercomplete/share.png'
const shareVideoPath = IMAGES_PREFIX + '/assets/images/ordercomplete/video.png'
const facebookShare = 'http://www.facebook.com/sharer.php?s=100&p[title]=example&p[summary]=summary&p[url]=example.com'
const support = 'https://support.songoven.com/portal/en/home'
const artistCover = IMAGES_PREFIX + '/assets/images/artists/gitarr.jpg'

export {
    support,
    meterImg,
    songPath,
    dateImage,
    orderPath,
    artistsPath,
    artistPath,
    artistCover,
    stepperIcon,
    occasionPath,
    justHearPath,
    downloadPath,
    shareSongPath,
    orderCompPath,
    facebookShare,
    shareVideoPath,
    orderOvelayImg,
    activeDateImage,
    orderCompleteImg,
    stepperIconActive,
    defaultArtistImage,
    defaultArtistImage1,
}